.login-form-button {
    width: 100%;
}

.login-form {
    width: 100%;
}

.login-left-side {
    margin: auto !important;
    animation-delay: 3s;
    -webkit-animation-delay: 3s;
    animation: fadein 5s;
    -moz-animation: fadein 5s; /* Firefox */
    -webkit-animation: fadein 5s; /* Safari and Chrome */
    -o-animation: fadein 5s; /* Opera */
}

.login-form-container {
    background-color: white;
    border-radius: 1em;
    margin: auto;
    width: 35vw !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    padding: 7% 10% !important;
}

/** Right Portion **/
.login-right-side {
    background-color: var(--primary-color) !important;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
}

.company-name {
    font-family: var(--primary-font-semibold) !important;
    font-size: 230%;
    line-height: 23px;
    color: var(--primary-color);
    line-height: 100%;
  }

  .company-address {
    font-family: var(--primary-font-light);
    font-size: 100%;
    line-height: 23px;
    color: var(--primary-green);
}

.login-logo {
    width: 25%;
    transform: scale(2);
    z-index: 1;
    object-fit: contain;
}

.entry-button {
    border-radius: 0.5em;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
    width: 80%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    font-size: 1.5vw;
    margin: 2%;
    cursor: pointer;
}

.homepage-date {
    font-family: var(--font-medium);
    justify-content: center;
    color: var(--black-primary-color);
    text-transform: uppercase;
    font-size: 3vw;
}

.homepage-day {
    font-size: 500%;
    color: var(--primary-green) !important;
    font-size: 3vw;
    justify-content: center;
}

.homepage-time {
    margin-top: 350px;
}

@media (max-width: 1024px) {
    .login-section {
        height: 30vh;
    }
}

@media (min-width: 1024px) {
    .login-section {
        height: 100vh;
    }
    .company-name {
        font-size: 200%;
    }
    .company-address {
        font-size: 80%;
    }
}

@media (min-width: 913px) and (max-width: 1023px) {
    .login-section {
        height: 100vh;
    }

    .login-left-side {
        margin-top: 3% !important;
    }

    .login-logo {
        width: 30%;
    }

    .login-right-side {
        margin-top: 3%;
        width: 60% !important;
        height: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        padding: 3% 0;
    }

    .login-form-container {
        width: 80% !important;
    }
}


/* tablet screens */
@media screen and (max-width: 912px) {
    .login-logo {
        width: 30%;
    }

    .login-left-side {
        margin-top: 10% !important;
    }

    .login-right-side {
        margin-top: 5%;
        width: 60% !important;
        height: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        padding: 3% 0;
    }

    .login-form-container {
        width: 80% !important;
    }

    .login-form .ant-input-affix-wrapper {
        padding: 1vw !important;
    }
}

@media screen and (max-width: 768px) {
    .login-form .ant-form-item-control-input-content {
        margin: 0 !important;
        padding: 0 !important;
    }
    .login-form .ant-form-item-control-input-content .ant-input-affix-wrapper {
        padding: 2vw !important;
        max-width: 200vw !important;
        width: 150% !important;
        margin-left: -25% !important;
    }

    .login-form .ant-form-item-label {
        padding-bottom: 0 !important;
    }
}

/* most phone screens */
@media screen and (max-width: 415px) {
    .login-left-side {
        margin-top: 10% !important;
    }
    .login-right-side {
        margin-top: 7%;
        width: 90% !important;
        height: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        padding: 3% 2%;
    }
    .login-form-container {
        width: 90% !important;
    }

    .login-form .prefix-input {
        max-width: 1000% !important;
        width: 200% !important;
        /* background-color: gold !important; */
    }

    .homepage-date {
        width: 100% !important;
        font-size: 5vw;
    }

    .homepage-date {
        font-size: 3vw;
    }

    .homepage-time {
        font-size: 6vw;
    }

    .login-form .ant-form-item-control-input-content .ant-input-affix-wrapper {
        padding: 3vw !important;
    }
    .company-name {
        font-size: 120%;
    }
    .company-address {
        font-size: 80%;
    }
    .login-logo {
        transform: scale(2);
    }
}