
.stripe-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-header-only-borders {
  border-collapse: collapse;
}

.table-header-only-borders th {
  border: 1px solid gray;
}

.table-header-only-borders td {
  border: none;
}

  @media print {
    .print-gov-report-page table tbody tr td {
      background-color: pink !important;
      font-size: 50% !important;
    }
  }