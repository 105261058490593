

.payroll-table-wrapper {
    width: 75vw !important;
    height: 65vh !important;
    overflow: auto;
}

.payroll-table-wrapper .ant-table-cell .ant-input {
    width: 8vw !important;
}

/* sticky first row or headers */
.payroll-table-wrapper .ant-table-thead {
    top: 0;
    position: sticky;
    z-index: 4;
}
/* sticky first row and column header */
.payroll-table-wrapper .ant-table-thead .ant-table-cell:first-child {
    top: 0;
    left: 0;
    position: sticky;
    z-index: 2 !important;
}

/* sticky first column */
.payroll-table-wrapper .ant-table-tbody tr .ant-table-cell:first-child {
    left: 0;
    position: sticky;
    z-index: 3 !important;
    background-color: white;
}

.payroll-table-wrapper .ant-table-tbody tr .ant-table-cell:first-child .ant-input {
    width: auto !important;
}

.payroll-table-wrapper .ant-table-thead tr .ant-table-cell:last-child div,
.payroll-table-wrapper .ant-table-tbody tr .ant-table-cell:last-child .ant-input {
    width: 10vw !important;
}

.no-vertical-scroll {
    overflow-x: hidden;
}

.no-style-btn, .no-style-btn a {
    text-decoration: none !important;
    background-color: transparent;
    border: none;
    padding: 0;
    font-style: none !important;
}

.ant-btn-primary .no-style-btn, .no-style-btn a:hover {
  color: white !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.payslip-logo {
    width: 10% !important;
    padding: 0 !important;
}

.contract-container {
    background-color: white;
    padding: 2% 5%;
}

  .sharp-edge {
    border-radius: 0 !important;
  }
  
  .bordered {
    border: 1px solid  rgb(171, 168, 168);
    /* border-color: rgb(171, 168, 168) !important; */
  }

  .center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ant-typography {
    margin: 0 !important;
    color: var(--primary-color) !important;
  }

  .border-right {
    border-right: 1px solid rgb(171, 168, 168) ;
  }

  .primary-bg {
    background-color: var(--primary-color);
  }

  .secondary-bg {
    background-color: var(--secondary-color);
  }

  .bordered-body, .bordered-body td {
    border: 1px solid gray !important;
}

  .loader-icon {
    width: 300% !important;
    transform: scale(3) !important;
  }

  .sss-logo {
    width: 40% !important;
  }

  .bw-cells {
    background-color: rgba(0, 0, 0, 0.516) !important;
    color: white;
}

.bw-cells th, .bw-cells td {
  border:1px solid black !important;
}

.view-page-logo {
  width: 100%;
  transform: scale(1.4);
}

.pdf-payslip,
.pdf-payslip .ant-col,
.pdf-payslip .ant-row {
  font-size: 10px !important;
}

.bold-cell td {
  font-weight: bold !important;
}

@media screen {
    .printOnly {
        display: none;
    }

    .always-hidden {
        display: none;
    }
}

@page {
    margin: 80px !important;
    font-size: 40%;
}

@media print {
    .contract-container {
        padding: 0 !important;
    }

    .ant-table-pagination {
        display: none !important;
    }

    .ant-table-content {
        font-size: 10px !important;
    }

    .print-report-page {
        padding: 5% 0 !important;
    }

    .print-report-page article {
        font-size: 2vw !important;
    }


    .noPrint {
        display: none !important;
    }

    .pdf-payslip {
      font-size: 10px !important;
    }
}


/* RESPONSIVENESS */

@media screen and (max-width: 540px) {
    .contract-container,
    .contract-container .ant-row,
    .contract-container .ant-col,
    .contract-container .ant-typography {
        font-size: 60% !important;
    }
}