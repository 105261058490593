.instruction-container {
    background-color: var(--primary-color);
    font-family: var(--font-bold);
    color: white;
    justify-content: center;
    padding: 2%;
    border-radius: 2em 2em 0em 0em;
    font-size: 4vh;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.pink-bg {
    background-color: pink !important;
}