
.custom-divider {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    margin-left: -1em !important;
}

.primary-color {
    color: var(--primary-color);
}

.ant-btn-primary {
    background-color: var(--primary-color);
}

.ant-btn-primary:disabled:hover {
    background-color: transparent !important;
    transform: scale(1.1);
}

.ant-btn-primary:hover {
    background-color: var(--primary-color) !important;
    transform: scale(1.1);
}

.ant-layout-sider {
    overflow-y: scroll;
    background-color: var(--primary-color) !important;
}

.ant-upload-drag {
    height: 11vh !important;
}

.ant-input-number,
.ant-picker {
    width: 100%;
}

.ant-col {
    padding: 0px 1px;
}

.ant-select,
.ant-select-selector,
.ant-select-selection-search,
.ant-select-selection-item,
.ant-select-selection-search-input {
    text-align: left !important;
}

.ant-select-selector .ant-select-selection-item  {
    display: flex !important;
    justify-content: left !important;
    text-align: left !important;
}

.upload-icon {
    transform: scale(2);
}

.ql-toolbar {
    border-radius: 0.5em 0.5em 0 0 !important;
}

.ql-container {
    border-radius: 0 0 0.5em 0.5em !important;
}

.ant-divider {
    margin: 2% 0 !important;
}

.danger {
    color: red !important;
}

.ant-dropdown-trigger {
    border: 0.5px solid rgb(234, 225, 225);
    padding: 5% 10%;
    border-radius: 0.2em;
}
.ant-dropdown-link {
    text-decoration: none;
    color: inherit;
}

.profile-pic-container {
    display: flex !important;
    justify-content: center !important;
}

.profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.underlined-text {
    text-decoration: underline;
    margin: 0 2px;
}

.white-text {
    color: white !important;
}

.invisible-text {
    color: transparent;
}

.black-divider {
    background-color: black !important;
    border-color: black !important;
}

.anchor {
    text-decoration: none !important;
    color: var(--primary-color) !important;
    font-weight: bolder;
}

.anchor:hover {
    float: inherit !important;
    /* font-size: 105% !important; */
}

.ant-input-status-success {
    padding: 1.1% !important;
}

.ant-collapse, .custom-collapse {
    border: none !important;
}

.ant-collapse-item {
    border-radius: 0.5em !important;
    background-color: var(--secondary-color);
    margin: 1% 0;
}

.ant-collapse-content-active {
    border: 1px solid var(--secondary-color);
    border-radius: 0 0 0.5em 0.5em;
}

.center {
    justify-content: center !important;
}

.tiny-text {
    font-size: 60%;
}

.larger-text {
    font-size: 140%;
}

.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase !important;
}

@media (max-width: 279px) {
    .ant-typography {
        font-size: 80% !important;
    }
}

@media (max-width: 376px) {
    .segment-container {
        margin: 0px 5px !important;
    }

    .ant-btn {
        font-size: 80% !important;
    }
}

@media (max-width: 530px) {
    .ant-segmented-item-label {
        font-size: 50% !important;
    }

    .segment-container {
        display: flex !important;
        justify-content: center !important;
    }

    .view-btn {
        width: 20% !important;
        font-size: 60% !important;
    }

    .avatar-editor-image {
        transform: scale(0.7) !important;
    }

    .view-page .ant-card-body .ant-row .ant-col {
        font-size: 70% !important;
    }
}

@media (max-width: 768px) {
    .ant-typography {
        font-size: 95% !important;
    }

    .ant-table, .ant-tag {
        font-size: 60% !important;
    }

    .ant-btn-default {
        max-width: 45vw !important;
        transform: scale(0.6);
    }

    .ant-space {
        margin-left: 1%;
        /* width: 100vw !important; */
        display: flex;
        justify-content: end;
    }

    .ant-space-item {
        transform: scale(1);
        max-width: 31vw !important;
        margin-inline-end: -1em !important;
    }

    .ant-btn-primary, .ant-input-affix-wrapper, .ant-picker-range {
        max-width: 45vw !important;
        transform: scale(0.6);
    }

    .banner-img {
        width: 100% !important;
    }
}

@media ((min-width: 767px) and (max-width: 820px)) {
    .ant-space {
        margin-left: 1%;
        display: flex;
        justify-content: end;
    }

    .ant-space-item {
        max-width: 35vw !important;
    }

    .ant-btn-primary, .ant-input-affix-wrapper, .ant-picker-range {
        max-width: 35vw !important;
    }
}

@media print {
    .view-page .ant-card-body .ant-row .ant-col,
    .view-page .ant-typography,
    .view-page .ant-table-wrapper {
        font-size: 60% !important;
    }

    .view-page .ant-card-body {
        padding: 2% !important;
    }

    .view-page .ant-table-wrapper .ant-table-cell {
        padding: 1% !important;
        font-size: 80% !important;
    }
}