.main-content {
    overflow-y: scroll; /* Enable vertical scrolling */
    background-color: #fff;
  }
  
  /* Optional: Customize the scrollbar appearance */
  .main-content::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }
  
  .main-content::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color as needed */
  }
  
  .main-content::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the hover color as needed */
  }

  .form-content {
    padding-top: 30px;
    padding-right: 50px;
    padding-left: 50px;
  }

  .navbar-menu-container,
  .navbar-menu-container .ant-menu-submenu .ant-menu-submenu-title {
    background-color: var(--primary-color);
    color: white;
  }

  .navbar-menu-container .ant-menu-item-selected {
    background-color: white;
    color: var(--primary-color);
  }

  .navbar-menu-container .ant-menu-item:hover {
    background-color: white !important;
    color: var(--primary-color) !important;
  }

  .navbar-logo {
    width: 20%;
    transform: scale(2);
  }

  /* CSS for the Navbar component */
.menu-cont {
  .ant-menu-item {
    height: 40px; /* Set the desired height */
  }

  .ant-menu-sub {
    margin-bottom: 8px;
  }

  .support-icon {
    transform: scale(0.7) !important;
  }

  @media (max-width: 600px) {
  .form-content {
    padding-right: 0px;
    padding-left: 0px;
  }
}}