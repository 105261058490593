body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --primary-color: #4e6226;
  --secondary-color: #e5f1ca;
  --tertiary-color: #cdd9b1;
  --fourth-color: #3c3c43;
  --fifth-color: #b9eeff;
  --text-color: #3c3c43;
  --background-color: #d3f3fa;

  --primary-font-light: 'Rubik-Light';
  --primary-font-regular: 'Rubik-Regular';
  --primary-font-medium: 'Rubik-Medium';
  --primary-font-semibold: 'Rubik-SemiBold';
  --primary-font-bold: 'Rubik-Bold';
}

/* FONTS */
@font-face {
  font-family: "Rubik-Light";
  src: url("../src/assets/fonts/Rubik/static/Rubik-Light.ttf");
}
@font-face {
  font-family: "Rubik-Medium";
  src: url("../src/assets/fonts/Rubik/static/Rubik-Medium.ttf");
}
@font-face {
  font-family: "Rubik-Regular";
  src: url("../src/assets/fonts/Rubik/static/Rubik-Regular.ttf");
}
@font-face {
  font-family: "Rubik-SemiBold";
  src: url("../src/assets/fonts/Rubik/static/Rubik-SemiBold.ttf");
}
@font-face {
  font-family: "Rubik-Bold";
  src: url("../src/assets/fonts/Rubik/static/Rubik-Bold.ttf");
}


/* Prettier scroll bars */

::-webkit-scrollbar {
  width: 8px; /* Vertical scroll bar  */
  height: 8px; /* Horizontal scroll bar */
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change the background color as desired */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #b3b3b3; /* Change the handle color as desired */
  border-radius: 4px; /* Adjust the border radius as desired */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #797979; /* Change the handle color on hover as desired */
}
